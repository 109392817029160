import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class BreadcrumbElement {
  link: string;
  name: string;
  constructor(link: string, name: string) {
    this.link = link;
    this.name = name;
  }
}

@Injectable({ providedIn: 'root' })
export class ToolsService {
  constructor() {
    //router.events.subscribe((url:any) => console.log('url:', url));
    //this.pageName = new BehaviorSubject<string>("BC");
  }
  public pageName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public menuVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public subNavigation: BehaviorSubject<BreadcrumbElement[]> = new BehaviorSubject<BreadcrumbElement[]>([]);

  public setUpPageName(name: string) {
    this.subNavigation.next([]);
    this.pageName.next(name);
  }

  public setMenuVisibility(value: boolean) {
    this.menuVisible.next(value);
  }

  public setUpSubBreadcrumbs(elements: BreadcrumbElement[]) {
    this.subNavigation.next(elements);
  }
}
